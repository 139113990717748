import React from "react";
import MenuPanel from '../components/menuPanel/MenuPanel.component';

const MenuPage = () => {
  return (
    <MenuPanel />
  )
};

export default MenuPage;
